import { make } from 'vuex-pathify'
import Vue from 'vue'
import axios from '../../util/http-common'

const state = {
  companies: {},
  selectedCompanyId: 0,
  selectedCompanyName: '',
  selectedCompanyPath: [],
}

const mutations = {
  ...make.mutations(state),
  updateSelectedCompany (state, companyId) {
    const company = state.companies[companyId]

    state.selectedCompanyId = company?.id || 0
    state.selectedCompanyName = company?.name || ''
    state.selectedCompanyPath = company?.path || []
  },
  updateCompanies (state, { companyId, companies }) {
    function mapCompany (company) {
      return {
        id: company.id,
        name: company.name?.trim() || '',
        image: company.image,
      }
    }
    function getPath (result, company) {
      result = [mapCompany(company), ...result]

      if (!company.parentId) {
        return result
      }

      const parent = state.companies[company.parentId]
      if (!parent) {
        return result
      }

      return getPath(result, parent)
    }

    for (const company of companies) {
      company.parentId = companyId
      company.path = getPath([], company) || []
      company.pathStr = company.path?.map(x => x.name)?.join(' / ') || ''

      if (companyId !== 0) {
        const parentCompany = state.companies[companyId]
        parentCompany.children ??= []
        parentCompany.children.push(company)
      }

      Vue.set(state.companies, company.id, company)
    }
  },
}

const actions = {
  ...make.actions(state),
  selectCompany: ({ commit }, companyId) => {
    commit('updateSelectedCompany', companyId)
  },
  loadCompanies: async ({ commit }, companyId) => {
    try {
      const response = await axios.get('Company/GetSubCompaniesPagination', {
        params: {
          ...(companyId && { companyId }),
          Page: 1,
          Limit: 50,
        },
      })

      if (Array.isArray(response.data) && response.data.length > 0) {
        const companies = response.data.map((x) => {
          // eslint-disable-next-line no-prototype-builtins
          if (!x.hasOwnProperty('children')) {
            x.children = []
          }
          return x
        })

        commit('updateCompanies', { companyId, companies })
        return companies
      }
    } catch (error) {

    }

    return []
  },
}

const getters = {
  getBaseCompanies: state => {
    return Object.values(state.companies).filter(x => x.parentId === 0)
  },
  getCompanies () {
    function mapCompany (company) {
      return {
        id: company.id,
        name: company.name?.trim() || '',
        pathStr: company.pathStr || '',
      }
    }

    return Object.values(state.companies)?.map(mapCompany)?.sort((a, b) => a.pathStr?.localeCompare(b.pathStr)) || []
  },
}

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
}
